<template>
	<tr v-if="!isEmptyWithoutHtml(value) " class="hvaField">
		<div class="text">
			<h3>{{label}}</h3>
		</div>
		<div class="container-div">
			<div class="data-clean text-content">
				<readMore :more-str="$t('general.readMore')" link="#" :less-str="$t('general.readLess')" :maxHeight="200">
					<div v-html="input"></div>
				</readMore>
			</div>
		</div>
	</tr>
</template>
<script>
import ReadMore from "common/components/General/ReadMoreHeight";

export default {
	name: "field",
	props: ["value", "label"],
	components: {
		ReadMore
	},
	computed: {
		input: {
			get() {
				return this.value;
			}
		}
	}
};
</script>