import { render, staticRenderFns } from "./ReadMoreHeight.vue?vue&type=template&id=4e386755&scoped=true"
import script from "./ReadMoreHeight.vue?vue&type=script&lang=js"
export * from "./ReadMoreHeight.vue?vue&type=script&lang=js"
import style0 from "./ReadMoreHeight.vue?vue&type=style&index=0&id=4e386755&prod&lang=less&scoped=true"
import style1 from "./ReadMoreHeight.vue?vue&type=style&index=1&id=4e386755&prod&lang=less"


/* normalize component */
import normalizer from "!../../../TweeAT.HvA.CourseCatalog/ClientApp/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e386755",
  null
  
)

export default component.exports